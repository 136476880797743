import React from "react";

export interface TileProps {
  title: string;
  content: string;
  icon?: string;
  buttonText?: string;
  onClick?: () => void;
  startCheckInEnabled?: boolean;
}

const Tile: React.FC<TileProps> = ({
  title,
  content,
  buttonText,
  onClick,
  startCheckInEnabled,
}) => {
  return (
    <div className="card bg-primary text-accent">
      <div className="card-body py-5 px-6">
        <h2 className="card-title">{title}</h2>
        <p>{content}</p>

        {startCheckInEnabled && onClick && buttonText && (
          <div className="card-actions justify-end pt-5">
            <button className="btn" onClick={onClick}>
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tile;
