import React, { useEffect, useState } from "react";
import { LocalizedParticipantQuestion } from "../../apis/API";
import { styles } from "../../shared-styles/styles";
import { strings } from "../../resources/strings";

export interface QuestionsListItemProps {
  localizedQuestion?: LocalizedParticipantQuestion;
  questionIndex: number;
  questionText: string;
  handleAnswerChange: (
    idx: number,
    answer: string,
    explanation: string | null,
    questionId: string,
  ) => void;
  setIsEditing: (isEditing: boolean) => void;
}

const QuestionsListItem: React.FC<QuestionsListItemProps> = ({
  localizedQuestion: questionData,
  questionIndex,
  questionText,
  handleAnswerChange,
  setIsEditing,
}) => {
  // Store yes/no answer for current question
  const [selectedAnswer, setSelectedAnswer] = useState<boolean | null>(null);

  // Store text-explanation for answers with 'yes' selected
  const [answerText, setAnswerText] = useState<string>("");

  // Show/hide text input area for answers with 'yes' selected
  const [showAnswerText, setShowAnswerText] = useState<boolean>(false);

  // If the text-input area is shown, assume the user is editing the answer and hide the `Submit` button
  useEffect(() => {
    setIsEditing(showAnswerText);
  }, [showAnswerText, setIsEditing]);

  /**
   * Selecting 'No' will imediately update the parent state
   */
  const selectedNo = () => {
    // Setting false for 'No' answer
    setSelectedAnswer(false);

    // Hide the text input area if it was previously shown
    setShowAnswerText(false);

    // Call the parent component's function to track the selected answer for this question
    handleAnswerChange(questionIndex, "No", "", questionData?.id || "");
  };

  /**
   * Selecting 'Yes' will show the text input area, but won't update the parent state until the user clicks the 'Save' button
   * - Called when the user clicks the 'Yes' button
   */
  const selectedYes = () => {
    // Setting false for 'Yes' answer
    setSelectedAnswer(true);
    // Show the text input area
    setShowAnswerText(true);
  };

  /**
   * Save the answer and notes to the parent state
   * - Called when the user clicks the 'Save' button after entering text explanation for a 'Yes' answer
   */
  const saveAnswerWithNotes = () => {
    setShowAnswerText(false);
    handleAnswerChange(
      questionIndex,
      "Yes",
      answerText,
      questionData?.id || "",
    );
  };

  /**
   * Handle text input change (Will allow us to perform text validation in the future, e.q., requiring a minimum number of characters, etc.)
   * - State is updated with each keystroke
   */
  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setAnswerText(event.target.value);
  };

  return (
    <div className="w-full bg-white shadow-xl rounded-xl p-2">
      <div className="flex justify-between items-center">
        {/* Question text */}
        <h5 className={styles.questionText}>{questionText}</h5>
        {/* Yes/No answer buttons */}
        <div className="flex flex-row flex-nowrap space-x-2 ml-5">
          <button
            className={`btn p-3 font-bold ${
              selectedAnswer === true
                ? "bg-primary text-white hover:bg-primary flex-nowrap whitespace-nowrap"
                : ""
            }`}
            onClick={selectedYes}
          >
            {strings.questionAnswers.yes}
          </button>
          <button
            className={`btn p-3 font-bold ${
              selectedAnswer === false
                ? "bg-primary text-white hover:bg-primary"
                : ""
            }`}
            onClick={selectedNo}
          >
            {strings.questionAnswers.no}
          </button>
        </div>
      </div>

      {showAnswerText && (
        <div className={`py-4 transition-all duration-500 ease`}>
          <textarea
            value={answerText}
            onChange={handleTextAreaChange}
            placeholder={strings.questionAnswers.yesNotesPlaceholder}
            className="w-full p-2 border rounded"
          />
          {/* Flex div with the SAVE button pushed to the far right */}
          <div className="flex justify-end py-3">
            <button
              className={`${
                answerText.length > 0 ? "bg-primary" : "bg-primary opacity-30"
              } hover:bg-green-600 text-white px-4 py-2 rounded`}
              onClick={saveAnswerWithNotes}
            >
              {strings.save}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default QuestionsListItem;
