/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";

export const GET_DOCUMENT_LIST = gql`
  query GetDocumentList {
    getDocumentList {
      id
      name
      category {
        locale
        text
        __typename
      }
      createdAt
      contentType
      fileName
      read
      scope
      __typename
    }
  }
`;
