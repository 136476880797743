import { Outlet, Navigate } from "react-router-dom";
import { routes } from "../../router/routes";
import { useAuthenticator } from "@aws-amplify/ui-react";

const AuthLayout = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const isAuthenticated = (authStatus: string) =>
    authStatus === "authenticated";
  return (
    <>
      {isAuthenticated(authStatus) ? (
        <Navigate to={routes.dashboard.path} />
      ) : (
        <>
          <section className="flex flex-1 w-50 flex-row justify-center items-center bg-primary ">
            <img
              src="/repath-logo-vector.svg"
              className="hidden xl:block h-1/4 mr-[10%]"
              alt="repath logo"
            />
            <Outlet />
          </section>
        </>
      )}
    </>
  );
};

export default AuthLayout;
