import React, { useEffect, useState } from "react";
import { strings } from "../resources/strings";
import { routes } from "../router/routes";
import { Link } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useQuery } from "@apollo/client";
import { GET_PARTICIPANT_CASE } from "../apis/queries/getParticipantCase";
import { Case } from "../apis/API";
import Utils from "../utils/UtilityFunctions";

const NavBar: React.FC<any> = () => {
  const { user } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);

  const {
    data: caseData,
    loading: loadingCase,
    error: loadCaseError,
  } = useQuery(GET_PARTICIPANT_CASE, {
    skip: user ? false : true,
  });

  const [participantCase, setParticipantCase] = useState<Case | null>(null);
  const [sideMenuExpanded, setSideMenuExpanded] = useState(false);

  // Sets the participant case data when it is loaded
  useEffect(() => {
    const particpantCase: Case | null = caseData?.getParticipantCase;
    if (particpantCase) {
      setParticipantCase(particpantCase);
    }
  }, [caseData]);

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const isAuthenticated = (authStatus: string) =>
    authStatus === "authenticated";
  const { signOut } = useAuthenticator((context) => [context.user]);

  // Nav buttons to be rendered in the top Navigation Bar / Header
  const navHeaderRoutes = [
    routes.dashboard,
    routes.appointments,
    routes.documents,
    routes.termsOfUse,
    routes.officerProfile,
  ];

  // Nav buttons to be rendered in the expandable side-menu
  const sideMenuRoutes = [
    routes.dashboard,
    routes.appointments,
    routes.documents,
    routes.termsOfUse,
    routes.officerProfile,
  ];

  // Function to render the top header navigation buttons
  const configureNavigationHeader = () => {
    return navHeaderRoutes.map((item: any, index: number) => (
      <Link to={item.path} key={index}>
        <button className="btn btn-ghost text-accent hover:bg-accent-focus normal-case text-[0.9rem]">
          {item.name}
        </button>
      </Link>
    ));
  };

  // Function to render the content within the expandable side-menu
  const buildSideMenuContent = () => {
    return sideMenuRoutes.map((item: any, index: number) => (
      <Link to={item.path} key={index}>
        <div className="text-accent font-semibold hover:bg-accent-focus w-[100%] py-4 px-4">
          {item.name}
        </div>
      </Link>
    ));
  };

  const toggleSideMenu = () => {
    setSideMenuExpanded(!sideMenuExpanded);
  };

  const handleSignOut = () => {
    signOut();

    /**
     * This is a workaround to prevent an issue we are experiencing with the AWS Amplify
     * Authenticator component.
     *
     * The issue is reproduced via:
     * - sign in, refresh, and then sign out
     * - the user is redirected to the login page, but the form input fields are
     *   not properly displayed. (Shows "Username" input instead of "Phone Number")
     *
     * After reloading, the form input fields are displayed correctly.
     */
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  return isAuthenticated(authStatus) ? (
    <>
      <nav className="navbar h-[5rem] rounded-b-sm bg-primary px-0 py-0">
        <div className="drawer">
          <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
          <div className="drawer-content flex flex-col">
            {/* Navbar Drawer Button - Visible when screen width is small */}
            <div className="w-full flex-1 navbar px-0 mx-0">
              <div className="flex-none md:hidden">
                {/* Drawer Open Button (When device width is small, such as on mobile devices) */}
                <label
                  htmlFor="my-drawer-3"
                  aria-label="open sidebar"
                  className="btn btn-square btn-ghost text-white pr-0"
                  onClick={toggleSideMenu}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#ffffff"
                    viewBox="0 0 24 24"
                    className="inline-block w-6 h-6 stroke-current"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      fill="#fff"
                      d="M4 6h16M4 12h16M4 18h16"
                    ></path>
                  </svg>
                </label>
              </div>

              {/* RePath Logo button */}
              <div className="px-0 ml-2 mr-6 z-0">
                <Link
                  to={routes.dashboard.path}
                  className="btn btn-ghost normal-case text-accent text-xl hover:bg-accent-focus z-auto"
                >
                  <img
                    src="/repath-logo-vector.svg"
                    className="h-10"
                    alt="repath logo"
                  />
                </Link>
              </div>

              <div className="flex-1 w-full justify-between">
                {/* Flex Spacing between page nav buttons and profile icon button */}
                {/* Page Navigation buttons */}
                <div className="menu menu-horizontal">
                  {/* Nav buttons will hide when screen width becomes too small to fit on one line */}
                  <div className="hidden md:block">
                    {/* Navbar menu content rendered here, using the defined routes object */}
                    {configureNavigationHeader()}
                  </div>
                </div>

                {/* Profile button w/ dropdown menu - always in the top right corner */}
                <div className="inline">
                  {/* Dropdown/Pop-open Menu */}
                  <div className="dropdown dropdown-end dropdown-hover px-3">
                    <div
                      tabIndex={0}
                      className="btn btn-circle hover:ring hover:ring-[rgba(255,255,255,0.5)]"
                    >
                      <div className="flex items-center justify-center align-middle text-primary">
                        {loadingCase && (
                          <span className="loading loading-spinner"></span>
                        )}
                        {loadCaseError && <p>?</p>}
                        {participantCase?.participant?.firstName &&
                          participantCase?.participant?.lastName && (
                            <p>
                              {Utils.getInitials(
                                participantCase.participant.firstName,
                                participantCase.participant.lastName,
                              )}
                            </p>
                          )}
                      </div>
                    </div>
                    <ul
                      tabIndex={0}
                      className="dropdown-content z-[1] menu p-2 mr-1 shadow drop-shadow-md bg-base-100 rounded-box w-52"
                    >
                      <li className="hover:bg-slate-200 rounded-md font-semibold">
                        <button onClick={handleSignOut}>
                          {strings.signOut}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 
            Side Navigation Menu 
            - Button to open the side menu is visible when screen width is small
            - If the menu is not open, the width is set to zero to prevent the content overlay from receiving clicks
          */}
          <div
            className={`
              fixed inset-0 bg-black bg-opacity-50 transition-opacity 
              ${sideMenuExpanded ? "opacity-100" : "opacity-0"}
              ${sideMenuExpanded ? "w-100" : "w-0"}
              ${sideMenuExpanded ? "z-50" : "z-0"}
            `}
            onClick={toggleSideMenu}
          >
            <div
              className={`
              fixed inset-y-0 left-0 w-64 bg-primary rounded-r-lg shadow-lg
              transform transition-transform 
              ${sideMenuExpanded ? "translate-x-0" : "-translate-x-full"}`}
            >
              <div className="grid grid-cols-1 divide-y-2 divide-[rgba(255,255,255,0.2)]">
                {buildSideMenuContent()}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  ) : null;
};

export default NavBar;
