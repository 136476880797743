/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";

export const VALIDATE_IMAGE = gql`
  mutation ValidateParticipantImage($input: ValidateParticipantImageInput!) {
    validateParticipantImage(input: $input) {
      faceMatched
      validationProblem
      __typename
    }
  }
`;
