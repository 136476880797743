// DeviceInfo.tsx
import * as rdd from "react-device-detect";

interface DeviceInfo {
  ipAddress: string;
  browserType: string;
  deviceType?: string;
  deviceModel?: string;
  browser?: string;
  osVersion: string;
  operatingSystemType: string;
}

class DeviceInfoService {
  private static instance: DeviceInfoService;
  private deviceInfo: DeviceInfo | null = null;

  // Automatically inits the device info when the class is instantiated
  private constructor() {
    this.initializeDeviceInfo();
  }

  // Return the singleton instance of the DeviceInfoService
  public static getInstance(): DeviceInfoService {
    if (!DeviceInfoService.instance) {
      DeviceInfoService.instance = new DeviceInfoService();
    }

    return DeviceInfoService.instance;
  }

  // Call getDeviceInfo and store the result in the instance variable
  private async initializeDeviceInfo(): Promise<void> {
    try {
      this.deviceInfo = await DeviceInfoService.setDeviceInfo();
      console.log("DeviceInfo initialized: " + JSON.stringify(this.deviceInfo));
    } catch (error) {
      // Handle initialization error
      console.error("Error initializing DeviceInfo:", error);
    }
  }

  private static async getIPAddress(): Promise<string> {
    const response = await fetch("https://api64.ipify.org?format=json");
    const { ip } = await response.json();
    return ip ? ip : "uknown";
  }

  private static getDeviceType(): string {
    if (rdd.isMobile) {
      return "Mobile";
    } else if (rdd.isTablet) {
      return "Tablet";
    } else if (rdd.isSmartTV) {
      return "SmartTV";
    } else if (rdd.isConsole) {
      return "Console";
    } else if (rdd.isWearable) {
      return "Wearable";
    } else if (rdd.isDesktop) {
      return "Desktop";
    } else {
      return "Unknown";
    }
  }

  private static getBrowserType(): string {
    return rdd.browserName;
  }

  private static getOS(): string {
    const osName = rdd.osName;
    const osVersion = rdd.osVersion;
    return `${osName}/${osVersion}`;
  }

  private static getDeviceModel(): string {
    const vendor = rdd.mobileVendor;
    const model = rdd.mobileModel;
    return `${vendor}/${model}`;
  }

  private static async setDeviceInfo(): Promise<DeviceInfo> {
    const ipAddress = await this.getIPAddress();
    const deviceType = this.getDeviceType();
    const deviceModel = this.getDeviceModel();
    const browserType = this.getBrowserType();
    const osVersion = this.getOS();
    const operatingSystemType = this.getOS(); // UpdateStartedWorkflowStepFunction requires this field to be set

    return {
      ipAddress,
      deviceType,
      deviceModel,
      browserType,
      osVersion,
      operatingSystemType,
    };
  }

  public getDeviceInfo(): DeviceInfo | null {
    return this.deviceInfo;
  }
}

export default DeviceInfoService;
