/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";

export const GET_TERMS_AND_CONDITIONS = gql`
  query GetTermsAndConditions {
    getTermsAndConditions {
      document {
        author
        time
        version
        localization {
          locale
          text
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
