/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";

// Upload Participant Document after the file has been uploaded to S3
// Requirs the following variables: s3Key, fileName, time (epoch time of upload)
export const UPLOAD_PARTICIPANT_DOCUMENT = gql`
  mutation UploadParticipantDocument($input: UploadParticipantDocumentInput!) {
    uploadParticipantDocument(input: $input)
  }
`;
