import React, { useEffect, useState } from "react";
import { strings } from "../../resources/strings";
import { styles } from "../../shared-styles/styles";
import { Tile, PageContentLoading } from "../../components";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PARTICIPANT_CASE } from "../../apis/queries/getParticipantCase";
import {
  Case,
  PendingQuestions,
  StepInput,
  AddOnboardingStepInput,
  CompleteOnboardingInput,
} from "../../apis/API";
import Utils, { getCurrentTimeInSeconds } from "../../utils/UtilityFunctions";
import CheckInProcess from "../../components/Questions/CheckInProcess";
import { useAuthenticator } from "@aws-amplify/ui-react";
import OnboardingProcess from "../../components/Onboarding/OnboardingProcess";
import { ADD_ONBOARDING_STEP } from "../../apis/mutations/addOnboardingStep";
import { v4 as uuid } from "uuid";
import DeviceInfoService from "../../utils/DeviceInfo";
import { COMPLETE_ONBOARDING } from "../../apis/mutations/completeOnboarding";

const Dashboard: React.FC<any> = ({
  setIsOnboarded,
  isOnboarded,
}: {
  setIsOnboarded: Function;
  isOnboarded: boolean;
}) => {
  const { user } = useAuthenticator((context) => [context.user]);

  /**
   * Query to get the Participant's case data
   * - Disabled caching to prevent stale data from being displayed
   * - Uses the `refetch` function to manually refresh the data when the modal is dismissed after submitting check-in/questions
   * - skip will prevent the query from running if the user is not set
   */
  const {
    data: caseData,
    loading: loadingCase,
    error: loadCaseError,
    refetch: refreshCase,
  } = useQuery(GET_PARTICIPANT_CASE, {
    fetchPolicy: "no-cache",
    skip: user ? false : true,
  });

  // Passed as a prop to the Onboarding / Check-in modals to track the completed onboarding steps
  const [addOnboardingStepMutation] = useMutation(ADD_ONBOARDING_STEP);
  const [completeOnboardingMutation] = useMutation(COMPLETE_ONBOARDING);

  // Stores the case data once loaded, and controls the dashboard tile loading state
  const [participantCase, setParticipantCase] = useState<Case | null>(null);

  // Controls the opening and closing of the checkin/questions modal
  const [showCheckInModal, setShowCheckInModal] = useState<boolean>(false);

  // Flag to tell the CheckInProcess component that it was launched from onboarding
  const [checkInFromOnboarding, setCheckInFromOnboarding] =
    useState<boolean>(false);

  // Controls the opening / closing of the Onboarding modal
  const [showOnboardingModal, setShowOnboardingModal] =
    useState<boolean>(false);

  /**
   * - Enabling the Start CheckIn/Questions button by reading the `pendingQuestions` field from the case, if
   *   it is not null, then the tile-button should appear, allowing the user to submit check-ins / questions.
   *
   * - The `pendingQuestions` field is set by the backend when the current date is within the time window in
   *   which questions can be submitted. It is also set if the Officer manually requests question answers via the agency portal.
   */
  const [checkInQuestionsEnabled, setCheckInQuestionsEnabled] =
    useState<boolean>(false);

  // If onboarding, session Id is used for step tracking mutations, as well as the checkInId for the check-in process
  const [sessionId, setSessionId] = useState<string>("");

  const deviceInfo = DeviceInfoService.getInstance().getDeviceInfo();

  /**
   * Sets the participantCase object when the query data is loaded
   * - If the `pendingQuestions` field is not null, then the Start CheckIn/Questions button should be enabled
   * - The child component will call `removeStartCheckInButton` when the modal is dismissed, which will disable the button,
   *   as well as call the `refreshCase` function to rerun the Query to get the latest case data
   */
  useEffect(() => {
    const particpantCase: Case | null = caseData?.getParticipantCase;
    const pendingQuestions: PendingQuestions | null | undefined =
      particpantCase?.pendingQuestions;
    const onboardingCompletedAt: number | null | undefined =
      particpantCase?.onboardingCompletedAt;

    if (particpantCase) {
      // Update case state
      setParticipantCase(particpantCase);

      // Determines if Onboarding is required
      // - Case returns `onboardingCompletedAt` (epoch date of onboarding)
      // - If null, the user has not onboarded, or needs to onboard again
      if (Utils.isNullOrUndefined(onboardingCompletedAt) && !isOnboarded) {
        displayOnboardingModal();
      }

      // Enable / disable CheckInQuestions
      if (!Utils.isNullOrUndefined(pendingQuestions)) {
        setCheckInQuestionsEnabled(true);
      } else {
        setCheckInQuestionsEnabled(false);
      }
    }
  }, [caseData, isOnboarded]);

  /**
   * Modal State Controls
   * - CheckIn/Questions Modal
   * - Onboarding Modal
   */

  const startCheckInProcess = () => {
    setShowCheckInModal(true);
  };

  const dismissQuestionsModal = () => {
    setIsOnboarded(true);
    setShowCheckInModal(false);
  };

  /**
   * Displays the Onboarding modal and sets the session Id to be provided as the checkInId
   * after accepting the terms of use
   */
  const displayOnboardingModal = () => {
    setSessionId(uuid());
    setShowOnboardingModal(true);
  };

  /**
   * Launches the Check-In process from the Onboarding modal after accepting the terms of use
   */
  const launchCheckInFromOnboarding = () => {
    // Dismiss the onboarding / terms of use modal
    setShowOnboardingModal(false);

    // Launch checkin with flag indicating it was launched from onboarding
    setCheckInFromOnboarding(true);

    setShowCheckInModal(true);
  };

  /**
   * Hides the `START` button in the "Next Questions" tile after the Check-In/Questions have been submitted
   * and the modal has been dismissed. Called by the child component `CheckInQuestionsModal`
   */
  const removeStartCheckInButton = () => {
    setCheckInQuestionsEnabled(false);
  };

  const addOnboardingStep = (step: StepInput) => {
    const input: AddOnboardingStepInput = {
      sessionId: sessionId,
      step: step,
      workflow: "Onboarding",
      time: getCurrentTimeInSeconds(),
      deviceInfo: JSON.stringify(deviceInfo),
    };
    performAddOnboardingStepMutation(input);
  };
  /**
   * Mutation to track the completed onboarding steps
   * step names: `Terms and Conditions` | `Photo` | `Check-in`
   */
  const performAddOnboardingStepMutation = async (
    input: AddOnboardingStepInput,
  ) => {
    try {
      await addOnboardingStepMutation({
        variables: {
          input: {
            sessionId: input.sessionId,
            step: input.step,
            workflow: input.workflow,
            time: input.time,
            deviceInfo: input.deviceInfo,
          },
        },
      });
    } catch (error) {
      console.error("Error saving raw event: ", error);
    }
  };

  const completeOnboarding = async () => {
    const input: CompleteOnboardingInput = {
      sessionId: sessionId,
      time: getCurrentTimeInSeconds(),
      deviceInfo: JSON.stringify(deviceInfo),
    };

    try {
      await completeOnboardingMutation({
        variables: {
          input: {
            sessionId: input.sessionId,
            time: input.time,
            deviceInfo: input.deviceInfo,
          },
        },
      });
    } catch (error) {
      console.error("Error saving onboarding-completed: ", error);
    }
  };

  return (
    <main data-theme="repath">
      {/* Displays a toast error if an error is thrown while querying the case */}
      {loadCaseError && (
        <>
          {console.error(
            "Failed to load participant case, error: ",
            loadCaseError,
          )}
          {Utils.toastError(strings.toast.loadCaseError)}
        </>
      )}

      {participantCase && !loadingCase && (
        <>
          <div className={styles.pageHeader}>
            <h2>{strings.pages.dashboard}</h2>
          </div>

          {/* Info tiles */}
          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols1 gap-4 px-5 py-5">
            <Tile
              title={
                checkInQuestionsEnabled
                  ? strings.questionsDue
                  : strings.nextQuestionsDate
              }
              content={
                checkInQuestionsEnabled
                  ? ""
                  : Utils.getNextQuestionsTime(participantCase)
              }
              buttonText={strings.start}
              onClick={startCheckInProcess}
              startCheckInEnabled={checkInQuestionsEnabled}
            />
          </div>
        </>
      )}

      {loadingCase && (
        <PageContentLoading loadingText={strings.pages.dashboard} />
      )}

      <OnboardingProcess
        isOpen={showOnboardingModal}
        participantCase={participantCase}
        refreshCase={refreshCase}
        addOnboardingStep={addOnboardingStep}
        launchCheckInFromOnboarding={launchCheckInFromOnboarding}
      />

      <CheckInProcess
        isOpen={showCheckInModal}
        isOnboarding={checkInFromOnboarding}
        sessionId={sessionId}
        participantCase={participantCase}
        dismiss={dismissQuestionsModal}
        removeStartCheckInButton={removeStartCheckInButton}
        refreshCase={refreshCase}
        addOnboardingStep={addOnboardingStep}
        onboardingCompleted={completeOnboarding}
      />
    </main>
  );
};

export default Dashboard;
