import { toast } from "react-toastify";
import { Case } from "../apis/API";

export function formatEventTime(eventTime: Date): string {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    timeZone: "UTC", // Set the desired timezone (e.g., UTC)
  }).format(eventTime);
}

const convertSecondsToDate = (seconds: number) => new Date(seconds * 1000);

export function dateStamp(seconds?: number) {
  if (!seconds) {
    return "";
  }

  return convertSecondsToDate(seconds).toLocaleDateString();
}

export function timeStamp(seconds?: number) {
  if (!seconds) {
    return "";
  }

  return convertSecondsToDate(seconds).toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
  });
}

// Used by the DocumentList component to format the date the document was created
export function formatDateCreatedAt(createdAt: number): string {
  const date = new Date(createdAt * 1000); // Convert seconds to milliseconds
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  // Format time to exclude seconds
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
  };
  const time = date.toLocaleTimeString([], options);

  return `${month}/${day}/${year} at ${time}`;
}

export function getCurrentTimeInSeconds() {
  return Math.floor(Date.now() / 1000);
}

export default class Utils {
  /**
   * NOTIFICATIONS
   */

  static toastSuccess(message: string) {
    toast.success(message);
  }

  // Toast errors will not auto-close, to ensure the user sees the error message
  static toastError(message: string) {
    toast.error(message, {
      autoClose: false,
    });
  }

  static toastErrorAutoClose(message: string) {
    toast.error(message);
  }

  /**
   * DATA VALIDATION
   */

  static isNullOrUndefined(val: any): boolean {
    return typeof val === "undefined" || val === null;
  }

  static isNullOrEmpty(val: any): boolean {
    return (
      Utils.isNullOrUndefined(val) ||
      val === "" ||
      Object.keys(val).length === 0
    );
  }

  /**
   * FORMATTING
   */

  static getNextQuestionsTime = (participantCase: Case) => {
    const seconds = participantCase?.nextQuestions?.time!;
    const date = dateStamp(seconds);
    const time = timeStamp(seconds);
    return date + " @" + time;
  };

  static getInitials = (firstName: string, lastName: string) => {
    let firstInitial = "";
    let lastInitial = "";
    if (firstName) firstInitial = firstName[0];
    if (lastName) lastInitial = lastName[0];
    return firstInitial + lastInitial;
  };
}
