import React from "react";

export interface LoadingIndicatorProps {
  loadingText?: string;
  customSize?: string; // ex: "60px" or "50%"
}
// This component is used to display a loading indicator + animation
// To set a custom width, pass in a string with the desired tailwind width class
// Example: customTailwindWidth="w-md" or customTailwindWidth="w-[60px]"
const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  customSize,
  loadingText,
}) => {
  return (
    <div className="flex justify-center flex-col items-center">
      {customSize ? (
        <span
          className={`loading loading-dots w-[${customSize}] text-primary`}
        />
      ) : (
        // Default to w-lg
        <span className="loading loading-dots w-[60px] text-primary" />
      )}

      {loadingText && (
        <div className="w-max">
          <h3 className="text-primary">{loadingText}</h3>
        </div>
      )}
    </div>
  );
};

export default LoadingIndicator;
