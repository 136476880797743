/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";

export const ACCEPT_PARTICIPANT_TERMS_AND_CONDITIONS = gql`
  mutation AcceptParticipantTermsAndConditions(
    $input: AcceptTermsAndConditionsInput!
  ) {
    acceptParticipantTermsAndConditions(input: $input)
  }
`;
