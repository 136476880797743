import { Navigate } from "react-router-dom";
import { routes } from "../router/routes";
import { ReactElement } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useQuery } from "@apollo/client";
import { GET_PARTICIPANT_CASE } from "../apis/queries/getParticipantCase";
import Utils from "../utils/UtilityFunctions";
import { Dashboard } from "../_root/pages";

const ProtectedRoute = ({
  children,
  isOnboarded,
  setIsOnboarded,
}: {
  children: ReactElement;
  isOnboarded: boolean;
  setIsOnboarded: Function;
}) => {
  // possible auth status states
  // * configuring - still authenticating
  // * authenticated
  // * unauthenticated
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const {
    data: caseData,
    loading: loadingCase,
    error: loadCaseError,
  } = useQuery(GET_PARTICIPANT_CASE);
  if (authStatus === "unauthenticated") {
    return <Navigate to={routes.login.path} replace />;
  }
  if (authStatus === "configuring" || loadingCase || loadCaseError) {
    return <>Loading...</>;
  }
  if (
    Utils.isNullOrUndefined(
      caseData?.getParticipantCase?.onboardingCompletedAt,
    ) &&
    !isOnboarded
  ) {
    return <Navigate to={routes.dashboard.path} replace />;
  }
  if (!isOnboarded) {
    setIsOnboarded(true);
  }

  return children;
};

export const DashboardRoute = ({
  setIsOnboarded,
  isOnboarded,
}: {
  setIsOnboarded: Function;
  isOnboarded: boolean;
}) => {
  // possible auth status states
  // * configuring - still authenticating
  // * authenticated
  // * unauthenticated
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  if (authStatus === "unauthenticated") {
    return <Navigate to={routes.login.path} replace />;
  }
  if (authStatus === "configuring") {
    return <>Loading...</>;
  }

  return (
    <Dashboard setIsOnboarded={setIsOnboarded} isOnboarded={isOnboarded} />
  );
};

export default ProtectedRoute;
