import React from "react";
import { strings } from "../resources/strings";

function Footer() {
  return (
    <footer className="footer mt-auto  p-4 text-slate-400 bg-transparent">
      <div className="items-center grid-flow-col">
        <p>{strings.footer.copyright}</p>
        <p>{strings.footer.version}</p>
        <p>{strings.footer.versionDate}</p>
      </div>
    </footer>
  );
}

export default Footer;
