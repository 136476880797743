import React, { useState } from "react";
import {
  IdentifiableAnswerInput,
  LocalizedParticipantQuestion,
} from "../../apis/API";
import { strings } from "../../resources/strings";
import QuestionsListItem from "./QuestionsListItem";

export interface QuestionsFormProps {
  participantQuestions: (LocalizedParticipantQuestion | null)[];
  submitAnswers: (answers: IdentifiableAnswerInput[]) => void;
}

const CheckInQuestionsForm: React.FC<QuestionsFormProps> = ({
  participantQuestions,
  submitAnswers,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [answers, setAnswers] = useState<{
    [key: number]: { answer: string; explanation: string; questionId: string };
  }>({});

  const handleAnswerChange = (
    idx: number,
    answer: string,
    explanation: string | null = null,
    questionId: string,
  ) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [idx]: { answer, explanation: explanation || "", questionId },
    }));
  };

  const isAllQuestionsAnswered = () => {
    return participantQuestions.every((question, index) => {
      const id = question!.id as string | undefined; // Type assertion to string | undefined
      return (
        id !== null && id !== undefined && answers[index]?.answer !== undefined
      );
    });
  };

  const handleSubmit = () => {
    // Convert the answers state to an array of IdentifiableAnswerInput
    const answersArray: IdentifiableAnswerInput[] = Object.values(answers).map(
      ({ questionId, answer, explanation }) => ({
        questionId,
        answer: answer === "Yes", // Yes=true, No=false
        notes: explanation || null,
      }),
    );

    // Call the submitAnswers function with the array of IdentifiableAnswerInput
    submitAnswers(answersArray);
  };

  return (
    <>
      <div className="flex flex-col  justify-evenly space-y-4">
        {participantQuestions?.map((question, index) => {
          // Get the question text for the current locale, which should always return 1 item
          const locale = question?.localization?.filter((questionLocale) =>
            navigator.language.includes(questionLocale?.locale ?? ""),
          );
          const questionText = locale?.[0]?.text ?? "";
          return (
            <div key={question?.id}>
              <QuestionsListItem
                localizedQuestion={question!}
                questionIndex={index}
                questionText={questionText}
                handleAnswerChange={handleAnswerChange}
                setIsEditing={setIsEditing}
              />
            </div>
          );
        })}

        {/* Render the submit button if all questions have been answered */}
        {isAllQuestionsAnswered() && !isEditing && (
          <button
            className="btn rounded-[100px] btn-ghost w-auto px-5 py-2 bg-primary text-accent self-center shadow-lg"
            onClick={handleSubmit}
          >
            <p>{strings.submitAnswers}</p>
          </button>
        )}
      </div>
    </>
  );
};

export default CheckInQuestionsForm;
