import React, { useState, useRef, Fragment } from "react";
import Webcam from "react-webcam";
import { strings } from "../../resources/strings";
import LoadingIndicator from "../shared/LoadingIndicator";
import Utils from "../../utils/UtilityFunctions";

interface CheckInPhotoStepProps {
  photoCaptured: (data: Uint8Array) => void;
}

const cameraViewConstraints = {
  facingMode: "user",
};

const CheckInPhotoStep: React.FC<CheckInPhotoStepProps> = ({
  photoCaptured,
}) => {
  const webcamRef = useRef<Webcam>(null);
  const [camLoading, setCamLoading] = useState<boolean>(true);
  const [showWebCam, setShowWebCam] = useState<boolean>(false);
  const [showCaptureBtn, setShowCaptureBtn] = useState<boolean>(false);

  /**
   * Callback function for when the webcam view has loaded
   * - Toggles visibility of the loading indicator and capture button
   * - Controls timing of the webcam view/capture button visibility
   */
  const cameraViewLoaded = () => {
    setTimeout(() => {
      setCamLoading(false);
      setShowWebCam(true);
    }, 1000);
    setTimeout(() => {
      setShowCaptureBtn(true);
    }, 1500);
  };

  /**
   * Capture the check-in photo and convert the PNG string data to a Uint8Array before starting the check-in flow
   */
  const capturePhoto = async () => {
    if (webcamRef.current) {
      // Get the image data from the webcam
      const imgSrc = webcamRef.current.getScreenshot();

      if (Utils.isNullOrEmpty(imgSrc)) {
        console.error("Failed to capture Check-In photo, imgSrc is null");
        Utils.toastErrorAutoClose(strings.toast.generalError);
        return;
      }

      // Extract the base64-encoded PNG data
      const base64Data = imgSrc!.split(",")[1];

      // Decode the base64 data to binary
      const binaryData = atob(base64Data);

      // Convert binary data to Uint8 byte-array and update the checkInState
      var imageData = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        imageData[i] = binaryData.charCodeAt(i);
      }

      // Update the parent state with the image data
      photoCaptured(imageData);
    }
  };
  return (
    <>
      {/* Render the webcam component */}
      <div className="card-body p-3">
        <div
          className={`
          transition-all duration-350 ease-in-out
          ${camLoading ? "opacity-100 visible h-auto" : "opacity-0 h-0 "}
        `}
        >
          <div className="absolute inset-0 w-fit h-fit mx-auto my-auto">
            <LoadingIndicator />
          </div>
        </div>
        <div
          className={`
          relative flex flex-col justify-center m-auto h-auto
          transition-opacity ease-in duration-500
          ${showWebCam ? "opacity-100" : "opacity-0"}
        `}
        >
          <Webcam
            className="rounded-xl"
            onUserMedia={cameraViewLoaded}
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/png"
            videoConstraints={cameraViewConstraints}
          />

          {/* If the webcam is loading, hide the capture button */}
          {showCaptureBtn && (
            <button
              onClick={capturePhoto}
              className={`
              absolute bottom-4 btn rounded-[30px] btn-ghost w-auto px-5  bg-primary text-accent self-center shadow-lg
              transition-all ease duration-500
              ${showCaptureBtn ? "opacity-100" : "opacity-0"}
            `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-7 h-7"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default CheckInPhotoStep;
