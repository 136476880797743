export const strings = {
  title: "RePath Portal",
  navTitle: "RePath Portal",
  profile: "Profile",
  signIn: "Sign In",
  signOut: "Sign Out",
  email: "Email",
  password: "Password",
  checkInPhotoNote:
    "When taking your photo, please ensure you have adequate lighting.",
  checkInModalTitle: "Check-In Photo",
  questionsModalTitle: "Questions",
  termsModalTitle: "Terms of Use",
  termsModalText:
    "Welcome to RePath! Please review and accept our Terms of Use to continue",
  acceptTermsBtn: "Accept Terms",
  questionsModalText: "Please submit your answers to the following questions",
  submitting: "Submitting...",
  submitAnswers: "Submit Answers",
  save: "Save",
  start: "Start",
  nextQuestionsDate: "Next Questions Date",
  questionsDue: "Check-In Questions Due!",
  loading: "Loading...",
  startButton: "Start",
  questionAnswers: {
    yes: "Yes",
    no: "No",
    yesNotesPlaceholder: "Please explain...",
  },
  officer: "Officer",
  pages: {
    dashboard: "Home",
    appointments: "Appointments",
    documents: "Documents",
    termsOfUse: "Terms of Use",
    officerProfile: "My Officer",
  },
  footer: {
    copyright: "Copyright @ eHawk Solutions 2023",
    version: "v1.0.0",
    versionDate: "Wed. Oct 25th, 2023",
  },
  documents: {
    myDocuments: "My Documents",
    uploadDocuments: "Upload Documents",
    fileType: "Type",
    fileName: "Name",
    uploadDate: "Upload Date",
    action: "Action",
    downloadBtn: "Download",
    deleteBtn: "Delete",
    dragDropText: "Drag & drop your files here",
    loadDocsError: "Error loading documents: ",
    downloadDocError: "Error downloading document: ",
  },
  appointments: {
    apptsHeader: "Appointments",
    courtHeader: "Court",
    courtTileTitle: "Court Date",
    appointmentTileTitle: "Appointment",
    upcomingCourtDate: "Upcoming court date on",
    upcomingAppt: "Upcoming appointment on",
    noUpcomingAppts: "No upcoming appointments",
    noUpcomingCourtDates: "No upcoming court dates",
  },
  toast: {
    generalError: "An error has occurred, please try again.",
    faceNotRecognized: "Face not recognized, please try again.",
    multipleFacesDetected: "Multiple faces detected, please try again.",
    noFaceDetected: "No face detected, please try again.",
    loadCaseError: "Error loading Participants case: ",
    checkInPhotoSuccess: "Check-In photo submitted",
    checkInPhotoError: "Failed to submit the Check-In photo, please try again.",
    questionAnswersSuccess: "Question answers successfully submitted",
    questionAnswersError: "Error submitting question answers!",
    uploadDocumentSuccess: "Document successfully uploaded",
    uploadDocumentError: "Error uploading document!",
    loading: "Please wait...",
    loadAppointmentsError: "Error loading appointments",
    loadTermsAndConditionsError:
      "Failed to load the Terms of Use, please refresh to try again.",
    acceptTermsSuccess: "Terms of Use accepted",
    acceptTermsError: "Failed to accept Terms of Use, please try again.",
  },
  pageContentLoading: "Loading",
};
