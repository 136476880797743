import React, { useState, useEffect } from "react";
import { strings } from "../../resources/strings";
import { styles } from "../../shared-styles/styles";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { GET_DOCUMENT_LIST } from "../../apis/queries/getDocumentList";
import { GET_DOCUMENT_DOWNLOAD_LINK } from "../../apis/queries/getDocumentDownloadLink";
import { UPLOAD_PARTICIPANT_DOCUMENT } from "../../apis/mutations/uploadParticipantDocument";
import {
  ParticipantDocument,
  UploadParticipantDocumentInput,
} from "../../apis/API";
import { LoadingIndicator } from "../../components";
import {
  formatDateCreatedAt,
  getCurrentTimeInSeconds,
} from "../../utils/UtilityFunctions";
import Utils from "../../utils/UtilityFunctions";
import { uploadData } from "@aws-amplify/storage";
import { v4 as uuid } from "uuid";
import { GET_PARTICIPANT_CASE } from "../../apis/queries/getParticipantCase";
import { toast } from "react-toastify";

const Documents: React.FC = () => {
  // Query - Get the list of documents for the current user
  const { loading, error, data } = useQuery(GET_DOCUMENT_LIST);

  // List of documents for the current user returned from the API
  const [docsList, setDocsList] = useState<ParticipantDocument[]>([]);

  // Calls the process method once the data is returned from the API
  useEffect(() => {
    processDocumentsData(data);
  }, [data]);

  // Process the data returned from the API once the query is complete
  const processDocumentsData = (data: any) => {
    if (data?.getDocumentList.length > 0) {
      const docsList: ParticipantDocument[] = data.getDocumentList.map(
        (document: ParticipantDocument) => document,
      );
      setDocsList(docsList.reverse());
    }
  };

  return (
    <main data-theme="repath">
      <div className={styles.pageHeader}>
        <h2>{strings.pages.documents}</h2>
      </div>

      {/* Render docs table component */}
      <div className="overflow-x-auto w-[90vw]  mx-auto mt-4 overflow-y-hidden">
        <DocumentsTable
          documents={docsList}
          isLoading={loading}
          _error={error}
        />
      </div>
    </main>
  );
};

export default Documents;

interface DocumentTableProps {
  documents: ParticipantDocument[] | undefined | null;
  isLoading: boolean;
  _error: any | undefined | null;
}

const DocumentsTable: React.FC<DocumentTableProps> = ({
  documents,
  isLoading,
  _error,
}) => {
  // State toggle to show/hide the upload view/my documents view
  const [showUploadView, setShowUploadView] = useState(false);

  // Build the table headers + rows
  const renderDocumentsView = () => {
    return (
      <div>
        <div className="flex flex-col">
          {/* Buttons to toggle between my documents / upload documents views */}
          <div className="flex justify-center space-x-4 mb-6">
            <button
              onClick={() => setShowUploadView(false)}
              className={`${
                !showUploadView ? "bg-blue-500 text-white" : "bg-gray-200"
              } px-4 py-2 rounded-[50px]`}
            >
              {strings.documents.myDocuments}
            </button>
            <button
              onClick={() => setShowUploadView(true)}
              className={`${
                showUploadView ? "bg-blue-500 text-white" : "bg-gray-200"
              } px-4 py-2 rounded-[50px]`}
            >
              {strings.documents.uploadDocuments}
            </button>
          </div>

          {/* Show Loading indicator only on `My Documents` view */}
          {isLoading && !showUploadView && (
            <div className="flex flex-1 flex-row justify-center m-auto py-10">
              <LoadingIndicator
                customSize="60px"
                loadingText="Loading Documents..."
              />
            </div>
          )}

          {/* Show error message only on `My Documents` view */}
          {_error && !showUploadView && (
            <h1>{strings.documents.loadDocsError + JSON.stringify(_error)}</h1>
          )}

          {/* Show 'My Documents' */}
          {documents && !showUploadView && !isLoading && (
            <div className="overflow-x-auto">
              <div className="p-1.5 w-full inline-block align-middle">
                <div className="overflow-hidden border rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    {/* Table Headers */}
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-primary uppercase"
                        >
                          {strings.documents.fileName}
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-primary uppercase"
                        >
                          {strings.documents.uploadDate}
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-primary uppercase"
                        >
                          {strings.documents.action}
                        </th>
                      </tr>
                    </thead>

                    {/* Table Rows (1 row per document) */}
                    <tbody className="divide-y divide-gray-200">
                      {/* Display data for each doc in the list */}
                      {documents &&
                        documents.map((document, index) => (
                          <tr key={index}>
                            <DocumentsTableRow document={document} />
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {showUploadView && <UploadDocuments />}
        </div>
      </div>
    );
  };

  return <>{renderDocumentsView()}</>;
};

interface DocumentTableRowProps {
  document: ParticipantDocument;
}

const DocumentsTableRow: React.FC<DocumentTableRowProps> = ({ document }) => {
  // Query - Returns the download link for the selected document
  // Note: This query is lazy loaded (only when the user clicks the download button
  //       PDFs will be opened in a new tab, non PDFs will be downloaded automatically
  const [getDocDownloadLink, { loading, error }] = useLazyQuery(
    GET_DOCUMENT_DOWNLOAD_LINK,
    {
      variables: {
        input: {
          docId: document.id,
          documentScope: document.scope,
        },
      },
      onCompleted: (data) => {
        if (data?.getDocumentDownloadLink) {
          window.open(data?.getDocumentDownloadLink, "_blank");
        }
      },
    },
  );

  return (
    <>
      {/* Display doc name */}
      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
        {document.name}
      </td>

      {/* Display creation date/time */}
      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
        {formatDateCreatedAt(document.createdAt)}
      </td>
      <td className="px-6 py-4 text-sm font-medium text-left whitespace-nowrap">
        {!loading ? (
          <button
            className="text-green-500 hover:text-green-700"
            onClick={() => {
              getDocDownloadLink();
            }}
          >
            {strings.documents.downloadBtn}
          </button>
        ) : (
          <div className="flex flex-row justify-start ml-4">
            <LoadingIndicator customSize="40px" />
          </div>
        )}
      </td>

      {error && alert(strings.documents.downloadDocError + error)}
    </>
  );
};

const UploadDocuments: React.FC = () => {
  // We will make use of the error and loading props later, only using data at the moment
  const { data: caseResponse } = useQuery(GET_PARTICIPANT_CASE);

  const [caseId, setCaseId] = useState<string | null>(null);
  const [isDragOver, setIsDragOver] = useState(false);

  // Mutation which uploads the doc to the participants case via the s3Key, fileName, and time created
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadDocumentMutation, { data, loading, error }] = useMutation(
    UPLOAD_PARTICIPANT_DOCUMENT,
  );

  // We will need to set a loading state to prevent the use from uploading before caseId has been resolved
  useEffect(() => {
    if (caseResponse?.getParticipantCase?.caseId) {
      setCaseId(caseResponse.getParticipantCase.caseId);
    }
  }, [caseResponse]);

  // File selection handler (browse file system)
  const handleFileSelection = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = e.target.files && e.target.files[0];
    if (file && caseId) {
      await uploadFileToS3(file, caseId);
      // clear the file name in the Chosen file display
      e.target.value = "";
    }
  };

  // Drag handler
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  // Drop handler
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);
    const file = e.dataTransfer.files && e.dataTransfer.files[0];
    if (file && caseId) {
      uploadFileToS3(file, caseId);
    }
  };

  // Upload file to S3
  const uploadFileToS3 = async (file: File, caseId: string) => {
    const id = uuid();
    const fileExtension: string = file.type;
    const s3Key = `participant-docs/${caseId}/${id}.${fileExtension}`;
    const mutationInput: UploadParticipantDocumentInput = {
      s3Key: s3Key,
      fileName: file.name,
      time: getCurrentTimeInSeconds(),
    };
    const toastId = toast.loading(strings.toast.loading);
    try {
      await uploadData({
        key: s3Key,
        data: file,
      }).result;

      // Call the mutation to upload the document to the participants case
      await performUploadDocumentMutation(mutationInput, toastId);
    } catch (error) {
      toast.done(id);
      Utils.toastError(
        strings.toast.uploadDocumentError + "\n Error: " + error,
      );
      console.error("Failed to upload the document, error: ", error);
    }
  };

  // Provide the document input data and call the mutation to upload the document to the participants case
  const performUploadDocumentMutation = async (
    input: UploadParticipantDocumentInput,
    toastId: number | string,
  ) => {
    try {
      await uploadDocumentMutation({
        variables: {
          input: {
            s3Key: input.s3Key,
            fileName: input.fileName,
            time: input.time,
          },
        },
        onCompleted: (data) => {
          if (data?.uploadParticipantDocument) {
            toast.done(toastId);

            data?.uploadParticipantDocument === true
              ? Utils.toastSuccess(strings.toast.uploadDocumentSuccess)
              : Utils.toastError(strings.toast.uploadDocumentError);
          }
        },
      });
    } catch (error) {
      toast.done(toastId);
      Utils.toastError(
        strings.toast.uploadDocumentError + "\n Error: " + error,
      );
      console.error(
        "Failed to attach the document to the Participants Case, error: ",
        error,
      );
    }
  };

  return (
    <>
      <div className="p-4 items-center flex flex-col">
        {/* Browse file system to upload */}
        <input
          type="file"
          onChange={handleFileSelection}
          className="file-input file-input-bordered file-input-primary w-full max-w-xs"
        />

        <div className="prose my-6">
          <h4>OR</h4>{" "}
        </div>

        {/* Drag & drop */}
        <div
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onDragEnter={() => setIsDragOver(true)}
          onDragLeave={() => setIsDragOver(false)}
          // className="border-dashed border-2 border-primary p-6 rounded-xl w-[30vw] text-center"
          className={`${
            isDragOver ? "border-primary" : "border-gray-200"
          } border-dashed border-2 p-6 rounded-xl w-[30vw] text-center`}
        >
          {strings.documents.dragDropText}
        </div>
      </div>
    </>
  );
};
