import {
  Dashboard,
  Appointments,
  Documents,
  TermsOfUse,
  OfficerProfile,
} from "../_root/pages";
import Login from "../_auth/pages/Login";

export const routes = {
  dashboard: {
    name: "Home",
    path: "/",
    element: <Dashboard />,
  },
  appointments: {
    name: "Appointments",
    path: "/appointments",
    element: <Appointments />,
  },
  documents: {
    name: "Documents",
    path: "/documents",
    element: <Documents />,
  },
  login: {
    name: "Login",
    path: "/login",
    element: <Login />,
  },
  termsOfUse: {
    name: "Terms of Use",
    path: "/terms",
    element: (
      <TermsOfUse
        setTermsOfUseAccepted={() => {}}
        shouldDisplayAccept={false}
      />
    ),
  },
  officerProfile: {
    name: "My Officer",
    path: "/officer",
    element: <OfficerProfile />,
  },
};
