import React, { useEffect } from "react";
import { strings } from "../../resources/strings";
import { styles } from "../../shared-styles/styles";
import { EventType } from "../../defs/Types";
import { ApptInfoTile, PageContentLoading } from "../../components";
import { GET_FUTURE_APPOINTMENTS } from "../../apis/queries/getFutureAppointments";
import { useQuery } from "@apollo/client";
import { Appointment } from "../../apis/API";
import Utils from "../../utils/UtilityFunctions";

const Appointments = () => {
  const {
    loading: apptsLoading,
    error: apptsError,
    data: apptsData,
  } = useQuery(GET_FUTURE_APPOINTMENTS);
  const [appointments, setAppointments] = React.useState<Appointment[]>([]);

  useEffect(() => {
    processAppointmentsData(apptsData);
  }, [apptsData]);

  const processAppointmentsData = (data: any) => {
    if (data?.getFutureAppointments.length > 0) {
      const futureAppointments: Appointment[] = data.getFutureAppointments.map(
        (appointment: Appointment) => appointment,
      );
      setAppointments(futureAppointments);
    }
  };

  const renderOrderedAppointments = () => {
    return appointments
      .filter((appointment) => appointment.type === EventType.Appointment)
      .map((appt, idx) => <ApptInfoTile key={idx} appt={appt} />);
  };

  const renderCourtDates = () => {
    return appointments
      .filter((appointment) => appointment.type === EventType.CourtDate)
      .map((courtAppt, idx) => <ApptInfoTile key={idx} court={courtAppt} />);
  };

  return (
    <main data-theme="repath">
      {apptsError && (
        <>
          {console.error(apptsError)}
          {Utils.toastError(strings.toast.loadAppointmentsError)}
        </>
      )}

      {/* Render court-ordered appointments and court dates tiles*/}
      {appointments && !apptsLoading && (
        <div className="flex flex-col justify-between space-y-5">
          <div className={styles.pageHeader}>
            <h2>{strings.appointments.courtHeader}</h2>
            {appointments.length === 0 && (
              <p>{strings.appointments.noUpcomingCourtDates}</p>
            )}
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols1 gap-4 px-5 py-5">
            {renderCourtDates()}
          </div>

          <div className={styles.pageHeader}>
            <h2>{strings.appointments.apptsHeader}</h2>
            {appointments.length === 0 && (
              <p>{strings.appointments.noUpcomingAppts}</p>
            )}
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols1 gap-4 px-5 py-5">
            {renderOrderedAppointments()}
          </div>
        </div>
      )}

      {apptsLoading && (
        <PageContentLoading loadingText={strings.pages.appointments} />
      )}
    </main>
  );
};

export default Appointments;
