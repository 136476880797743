import React from "react";
import LoadingIndicator from "./LoadingIndicator";
import { strings } from "../../resources/strings";

interface PageContentLoadingProps {
  loadingText: string;
}

const PageContentLoading: React.FC<PageContentLoadingProps> = ({
  loadingText,
}) => {
  return (
    <div className="absolute inset-0 w-screen  h-[calc(100vh-5rem)] mt-[5rem] pointer-events-none">
      <div className="flex flex-col h-full justify-center items-center scale-150">
        <div className="translate-y-[-4rem]">
          <LoadingIndicator />
          <h2 className="mb-2 text-lg font-semibold  tracking-tight text-gray-900">{`${strings.pageContentLoading} ${loadingText}...`}</h2>
        </div>
      </div>
    </div>
  );
};

export default PageContentLoading;
