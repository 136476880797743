/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";

export const CREATE_EVENT = gql`
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      checkInId
      checkInOutcome
      eventId
      id
      parentEventId
      time
      type
      __typename
    }
  }
`;
