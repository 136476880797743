import { routes } from "./router/routes";
import { Navigate, Route, Routes } from "react-router-dom";
import { Navbar } from "./components";
import AuthLayout from "./_auth/pages/AuthLayout";
import RootLayout from "./_root/pages/RootLayout";
import ProtectedRoute, { DashboardRoute } from "./components/ProtectedRoute";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import { awsConfig } from "./config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeviceInfoService from "./utils/DeviceInfo";
import { useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useIdleTimer } from "react-idle-timer";
import { GET_PARTICIPANT_CASE } from "./apis/queries/getParticipantCase";
import { useQuery } from "@apollo/client";
import Utils from "./utils/UtilityFunctions";

Amplify.configure(awsConfig, {
  Storage: {
    S3: {
      prefixResolver: async ({ accessLevel, targetIdentityId }) => {
        return ``;
      },
    },
  },
});

function App() {
  const { signOut, authStatus } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);
  const tenMinutes = 600000;
  const {
    data: caseData,
    //    loading: loadingCase,
    //    error: loadCaseError
  } = useQuery(GET_PARTICIPANT_CASE);
  const [isOnboarded, setIsOnboarded] = useState(false);
  if (
    !isOnboarded &&
    !Utils.isNullOrUndefined(
      caseData?.getParticipantCase?.onboardingCompletedAt,
    )
  ) {
    setIsOnboarded(true);
  }
  const handleIdle = () => {
    signOut();
    /**
     * This is a workaround to prevent an issue we are experiencing with the AWS Amplify
     * Authenticator component.
     *
     * The issue is reproduced via:
     * - 1. sign in, do not interact with the page (including moving the mouse) and wait for the idle time to be reach to force signOut
     * - the form input fields are not properly displayed. (Shows "Username" input instead of "Phone Number")
     *
     * After reloading, the form input fields are displayed correctly.
     */
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };
  useIdleTimer({ timeout: tenMinutes, onIdle: handleIdle });

  // When the app loads, initialize the device info service
  // After init, the device info will be available via getDeviceInfo()
  DeviceInfoService.getInstance();

  useEffect(() => {
    const initialize = () => {
      // this is necessary to tell the difference between a reload and a new tab
      // if it's a refresh we don't want the user to sign out.
      // if it's a new tab the user should be signed out.
      // the user is signed out on a new tab because the event beforeunload when a tab is closed is the same event that happens on a refresh,
      // so we can't tell if we should sign out or or allow the refresh.
      if (
        sessionStorage.getItem("loaded") !== "yes" &&
        authStatus === "authenticated"
      ) {
        signOut();
        /**
         * This is a workaround to prevent an issue we are experiencing with the AWS Amplify
         * Authenticator component.
         *
         * The issue is reproduced via:
         * - 1. sign in, open a new tab, close the signed in tab, navigate back to login page on the new tab
         * - 2. sign in, close browser, open browser and enter login url
         * - the form input fields are not properly displayed. (Shows "Username" input instead of "Phone Number")
         *
         * After reloading, the form input fields are displayed correctly.
         */
        // eslint-disable-next-line no-restricted-globals
        location.reload();
        sessionStorage.setItem("loaded", "yes");
      }
    };

    initialize();
  }, [signOut, authStatus]);

  return (
    <main data-theme="repath" className="flex flex-col h-screen bg-accent">
      {/* Nav will remove itself from the DOM when the app moves to the login page */}
      <Navbar />
      <ToastContainer position="top-center" theme="colored" />

      <Routes>
        {/* Public Routes */}
        <Route element={<AuthLayout />}>
          <Route path={routes.login.path} element={routes.login.element} />
        </Route>

        {/* Private Routes (requires auth) */}
        <Route element={<RootLayout />}>
          <Route
            path={routes.dashboard.path}
            element={
              <DashboardRoute
                setIsOnboarded={setIsOnboarded}
                isOnboarded={isOnboarded}
              />
            }
          />
          <Route
            path={routes.appointments.path}
            element={
              <ProtectedRoute
                setIsOnboarded={setIsOnboarded}
                isOnboarded={isOnboarded}
              >
                {routes.appointments.element}
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.documents.path}
            element={
              <ProtectedRoute
                setIsOnboarded={setIsOnboarded}
                isOnboarded={isOnboarded}
              >
                {routes.documents.element}
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.termsOfUse.path}
            element={
              <ProtectedRoute
                setIsOnboarded={setIsOnboarded}
                isOnboarded={isOnboarded}
              >
                {routes.termsOfUse.element}
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.officerProfile.path}
            element={
              <ProtectedRoute
                setIsOnboarded={setIsOnboarded}
                isOnboarded={isOnboarded}
              >
                {routes.officerProfile.element}
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </main>
  );
}

export default App;
