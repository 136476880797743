/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";

export const GET_FUTURE_APPOINTMENTS = gql`
  query GetFutureAppointments {
    getFutureAppointments {
      eventDay
      location {
        city
        country
        county
        formattedAddress
        label
        name
        number
        postalCode
        state
        stateShort
        street
        streetAddress
        sublocality
        __typename
      }
    time
    type
    resolvedAppointmentType {
      id
      label
      active
      __typename
    }
    appointmentInstructions
    notes
    __typename
  }
  }
`;
