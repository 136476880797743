import React, { useEffect, useState } from "react";
import { strings } from "../../resources/strings";
import { styles } from "../../shared-styles/styles";
import { PageContentLoading } from "../../components";
import { useQuery } from "@apollo/client";
import { GET_PARTICIPANT_CASE } from "../../apis/queries/getParticipantCase";
import { Case, Officer } from "../../apis/API";
import Utils from "../../utils/UtilityFunctions";

const OfficerProfile: React.FC<any> = () => {
  const {
    data: caseData,
    loading: loadingCase,
    error: loadCaseError,
  } = useQuery(GET_PARTICIPANT_CASE);
  const [currentOfficer, setCurrentOfficer] = useState<Officer | null>(null);

  useEffect(() => {
    const particpantCase: Case | null = caseData?.getParticipantCase;
    if (particpantCase?.currentOfficer) {
      setCurrentOfficer(particpantCase.currentOfficer);
    }
  }, [caseData]);

  return (
    <main data-theme="repath">
      {/* Displays a toast error if an error is thrown while querying the case */}
      {loadCaseError && (
        <>
          {console.error(
            "Failed to load participant case, error: ",
            loadCaseError,
          )}
          {Utils.toastError(strings.toast.loadCaseError)}
        </>
      )}

      {currentOfficer && (
        <div className={styles.pageHeader}>
          <h2>{strings.pages.officerProfile}</h2>
        </div>
      )}

      {currentOfficer?.firstName && currentOfficer?.lastName && (
        <div className="grid grid-cols-1 gap-4 px-5 py-5">
          <div className="flex items-center justify-center space-x-10">
            {/* Avatar placeholder (We can load the avatar / profile image from S3 in the future) */}
            <div className="avatar placeholder">
              <div className="bg-neutral text-neutral-content rounded-full w-28 h-28 flex items-center justify-center">
                <span className="text-4xl">
                  {Utils.getInitials(
                    currentOfficer.firstName,
                    currentOfficer.lastName,
                  )}
                </span>
              </div>
            </div>

            {/* Contact Info (name, phone, email) */}
            <div className="flex flex-col items-start ml-4 space-y-2">
              {/* Name */}
              <p className="text-2xl font-bold">
                {currentOfficer.firstName} {currentOfficer.lastName}
              </p>

              {/* Phone */}
              {currentOfficer.phone && (
                <p className="text-md text-gray-500 flex flex-row">
                  <span className="stroke-primary mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                      />
                    </svg>
                  </span>
                  {currentOfficer.phone}
                </p>
              )}

              {/* Email */}
              {currentOfficer.email && (
                <p className="text-md text-gray-500 flex flex-row">
                  <span className="stroke-primary mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                      />
                    </svg>
                  </span>
                  {currentOfficer.email}
                </p>
              )}
            </div>
          </div>
        </div>
      )}

      {loadingCase && (
        <PageContentLoading loadingText={strings.pages.officerProfile} />
      )}
    </main>
  );
};

export default OfficerProfile;
