import React, { useState } from "react";
import { styles } from "../../shared-styles/styles";
import { strings } from "../../resources/strings";
import { PageContentLoading } from "../../components";
import { useMutation, useQuery } from "@apollo/client";
import { GET_TERMS_AND_CONDITIONS } from "../../apis/queries/getTermsAndConditions";
import { ACCEPT_PARTICIPANT_TERMS_AND_CONDITIONS } from "../../apis/mutations/acceptParticipantTermsAndConditions";
import { AcceptTermsAndConditionsInput } from "../../apis/API";
import parse from "html-react-parser";
import { toast } from "react-toastify";

export interface TermsOfUseProps {
  setTermsOfUseAccepted: (termsOfUseAccepted: boolean) => void;
  shouldDisplayAccept: boolean;
}

const TermsOfUse = ({
  setTermsOfUseAccepted,
  shouldDisplayAccept,
}: TermsOfUseProps) => {
  const [isAcceptBeingProcessed, setIsAcceptBeingProcessed] = useState(false);
  const { loading, error, data } = useQuery(GET_TERMS_AND_CONDITIONS);
  const [acceptParticipantTermsAndConditionsMutation] = useMutation(
    ACCEPT_PARTICIPANT_TERMS_AND_CONDITIONS,
  );

  const handleAccept = async () => {
    const input: AcceptTermsAndConditionsInput = {
      versionId: data?.getTermsAndConditions?.document?.version || 1,
      time: new Date().valueOf(),
    };
    try {
      setIsAcceptBeingProcessed(true);
      const id = toast.loading(strings.toast.loading);
      const result = await acceptParticipantTermsAndConditionsMutation({
        variables: { input },
      });
      console.log(
        "acceptParticipantTermsAndConditionsMutation() -> result: ",
        result,
      );
      setIsAcceptBeingProcessed(false);
      setTermsOfUseAccepted(true);
      toast.done(id);
    } catch (error) {
      console.error(
        "acceptParticipantTermsAndConditionsMutation() -> error: ",
        error,
      );
    }
  };

  return (
    <main data-theme="repath">
      {error && (
        <>
          {console.error(error)}
          {toast.error(strings.toast.loadTermsAndConditionsError)}
        </>
      )}

      {data && (
        <>
          <div className={styles.pageHeader}>
            <h2>{strings.pages.termsOfUse}</h2>
          </div>
          {parse(data?.getTermsAndConditions?.document?.localization[0]?.text)}
        </>
      )}

      {data && shouldDisplayAccept && (
        <div className="text-center pt-4">
          <button
            className={`${
              !isAcceptBeingProcessed ? "bg-blue-500 text-white" : "bg-gray-200"
            } px-4 py-2 rounded-[50px]`}
            onClick={handleAccept}
            disabled={isAcceptBeingProcessed}
          >
            ACCEPT
          </button>
        </div>
      )}

      {loading && <PageContentLoading loadingText={strings.pages.termsOfUse} />}
    </main>
  );
};

export default TermsOfUse;
