import { Outlet } from "react-router-dom";
import { styles } from "../../shared-styles/styles";
import { Footer } from "../../components";

const RootLayout = () => {
  return (
    <>
      <section className={styles.pageContentWrapper}>
        <Outlet />
      </section>
      <Footer />
    </>
  );
};

export default RootLayout;
