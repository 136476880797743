import { useState, Fragment } from "react";
import { useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { v4 as uuid } from "uuid";
import { styles } from "../../shared-styles/styles";
import {
  AgencyQuestionGroup,
  Case,
  CreateEventInput,
  CreateParticipantAnswersInput,
  EventType,
  FaceValidationProblem,
  IdentifiableAnswerInput,
  LocalizedParticipantQuestion,
  PendingQuestions,
  SaveRawEventInput,
  StepInput,
  ValidateParticipantImageInput,
  ValidateParticipantImageResponse,
} from "../../apis/API";
import DeviceInfoService from "../../utils/DeviceInfo";
import { SAVE_RAW_EVENT } from "../../apis/mutations/saveRawEvent";
import { CREATE_PARTICIPANT_ANSWERS } from "../../apis/mutations/createParticipantAnswers";
import { CREATE_EVENT } from "../../apis/mutations/createEvent";
import { VALIDATE_IMAGE } from "../../apis/mutations/validateParticipantImage";
import LoadingIndicator from "../shared/LoadingIndicator";
import { strings } from "../../resources/strings";
import Utils, { getCurrentTimeInSeconds } from "../../utils/UtilityFunctions";
import CheckInPhotoStep from "./CheckInPhotoStep";
import CheckInQuestionsForm from "./CheckInQuestionsForm";
import { uploadData } from "@aws-amplify/storage";
import { S3UploadType } from "../../defs/Types";

interface CheckInModalProps {
  isOpen: boolean;
  isOnboarding?: boolean;
  sessionId?: string; // If onboarding, checkInId will be read from props
  participantCase?: Case | null;
  dismiss: () => void;
  removeStartCheckInButton: () => void;
  refreshCase: () => void; // Callback method to re-query the case after submitting Question Answers
  addOnboardingStep: (step: StepInput) => void; // Mutation to track the onboarding steps
  onboardingCompleted: () => void; // Callback method to close the onboarding modal
}

interface CheckInState {
  checkInId: string;
  imageData: Uint8Array | null;
  errors: string[];
  validationResults: ValidateParticipantImageResponse | null;
  checkInEventCreated: boolean;
  faceDetected: boolean;
}

const CheckInProcess: React.FC<CheckInModalProps> = ({
  isOpen,
  isOnboarding,
  sessionId,
  participantCase,
  dismiss,
  removeStartCheckInButton,
  refreshCase,
  addOnboardingStep,
  onboardingCompleted,
}) => {
  // API - Mutations
  const [createParticipantAnswersMutation] = useMutation(
    CREATE_PARTICIPANT_ANSWERS,
  );
  const [saveRawEventMutation] = useMutation(SAVE_RAW_EVENT);
  const [createEventMutation] = useMutation(CREATE_EVENT);
  const [validateImageMutation] = useMutation(VALIDATE_IMAGE);

  // Create an initial check-in state
  const checkInState: CheckInState = {
    checkInId: isOnboarding ? sessionId! : uuid(),
    imageData: null,
    errors: [],
    validationResults: null,
    checkInEventCreated: false,
    faceDetected: false,
  };

  // UI states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showWebCam, setShowWebCam] = useState<boolean>(true);
  const [showQuestions, setShowQuestions] = useState<boolean>(false);

  // Get the device info for the raw events
  const deviceInfo = DeviceInfoService.getInstance().getDeviceInfo();

  const questionGroup: AgencyQuestionGroup | null | undefined =
    participantCase?.questionAssignment?.agencyQuestionGroup;
  const participantQuestions:
    | (LocalizedParticipantQuestion | null)[]
    | null
    | undefined = questionGroup?.localizedParticipantQuestions;
  const pendingQuestions: PendingQuestions | null | undefined =
    participantCase?.pendingQuestions;

  const closeModal = () => {
    // If onboarding, call the parent mutation before dismissing
    if (isOnboarding) {
      onboardingCompleted();
    }
    dismiss();
    removeStartCheckInButton();
  };

  /**
   * Displays the loading indicator after submitting photo and questions
   */
  const renderSubmitting = () => {
    return (
      <div className="absolute inset-0 w-fit h-fit mx-auto my-auto">
        <LoadingIndicator />
        <h5 className={styles.modalHeader}>{strings.submitting}</h5>
      </div>
    );
  };

  /**
   * Called by the Child component `CheckInPhotoStep` after the photo has been captured
   * @param imageData - The image data captured from the webcam
   */
  const photoCaptured = (imageData: Uint8Array) => {
    checkInState.imageData = imageData;
    setShowWebCam(false);

    setTimeout(() => {
      setIsLoading(true);
    }, 200);

    saveRawEvent(EventType.CheckInPhotoTaken, {
      checkInId: checkInState.checkInId,
      deviceInfo: deviceInfo,
    });

    startCheckInFlow();
  };

  /**
   * Display the questions form
   * - Called after the check-in photo has been submitted and the Check-In event has been created
   */
  const displayQuestions = () => {
    setShowWebCam(false);
    setIsLoading(false);
    setShowQuestions(true);
    saveRawEvent(EventType.QuestionsPrompted, {
      checkInId: checkInState.checkInId,
      deviceInfo: deviceInfo,
    });
  };

  /**
   * Retake the check-in photo
   * - Called when validation returns an error or face is not matched
   */
  const retakePhoto = () => {
    setShowQuestions(false);
    setIsLoading(false);
    setShowWebCam(true);
    checkInState.imageData = null;
    checkInState.validationResults = null;
  };

  /**
   * Start the check-in flow after taking the photo
   * - Upload the image to S3, then call the mutation to validate the image with AWS Rekognition
   * - Read the validation results
   * - After successful validation, creates the Check-In event
   * - Prompts the user to answer questions
   */
  const startCheckInFlow = async () => {
    await validateCheckInPhoto();
    await checkValidationResults();

    // If a single face was detected (regardless of whether or not it was a match),
    // we'll still continue to submit the check-in photo
    if (checkInState.faceDetected) {
      await saveCheckInPhoto();
      await createCheckInEvent();
    }

    if (checkInState.checkInEventCreated) {
      saveRawEvent(EventType.CheckInSubmitted, {
        checkInId: checkInState.checkInId,
        errors: checkInState.errors,
        deviceInfo: deviceInfo,
      });

      if (!Utils.isNullOrEmpty(pendingQuestions)) {
        displayQuestions();
      } else {
        closeModal();
      }
    } else retakePhoto();
  };

  /**
   * Upload file to S3, then call the mutation to validate the image with the S3 key
   * @param imgSrc - The image data to upload to S3
   */
  const validateCheckInPhoto = async () => {
    if (Utils.isNullOrEmpty(checkInState.imageData)) {
      console.error("Failed to validate Check-In photo, imageData is null");
      Utils.toastErrorAutoClose(strings.toast.checkInPhotoError);
      retakePhoto();
      return;
    }

    const checkInId = checkInState.checkInId;
    const s3Key = `temp-images/${checkInId}.png`;
    const data = checkInState.imageData!;

    try {
      await uploadData({
        key: s3Key,
        data: data,
        options: {
          contentType: S3UploadType.PNG,
        },
      }).result;

      // Call the mutation to validate the image with the S3 key
      const input: ValidateParticipantImageInput = {
        s3ImagePath: s3Key,
      };

      await performImageValidationMutation(input);
    } catch (error) {
      console.error("Error validating check-in photo: ", error);
      Utils.toastErrorAutoClose(strings.toast.checkInPhotoError);
    }
  };

  /**
   * Check the validation results from the image validation mutation
   * - If the face matched, we'll submit the check-in results via createEvent
   * - If the face did not match, we'll display an error message and retake the photo
   */
  const checkValidationResults = async () => {
    if (Utils.isNullOrEmpty(checkInState.validationResults)) {
      console.error("Failed to check image-validation results, data is null");
      return;
    }
    const imageValidationResults = checkInState.validationResults!;
    const imageValidationError = imageValidationResults.validationProblem;

    // Check validation errors - If face wasn't a match, continue to submit the check-in
    // photo, as this will display as a failed check-in in the agency webapp
    if (!Utils.isNullOrEmpty(imageValidationError)) {
      switch (imageValidationError) {
        case FaceValidationProblem.FaceDoesNotMatch:
          checkInState.faceDetected = true;
          break;

        case FaceValidationProblem.MultipleFaces:
          Utils.toastErrorAutoClose(strings.toast.multipleFacesDetected);
          retakePhoto();
          break;

        case FaceValidationProblem.NoFaces:
          Utils.toastErrorAutoClose(strings.toast.noFaceDetected);
          retakePhoto();
          break;

        default:
          break;
      }
      console.error("Error validating check-in photo: ", imageValidationError);
      addError(imageValidationError!.toString());
    } else {
      checkInState.faceDetected = true;
    }
  };

  /**
   * Upload the check-in photo to S3
   */
  const saveCheckInPhoto = async () => {
    if (Utils.isNullOrEmpty(checkInState.imageData)) {
      console.error("Failed to save Check-In photo, imageData is null");
      Utils.toastError(strings.toast.checkInPhotoError);
      return;
    }

    try {
      await uploadData({
        key: `checkins/${checkInState.checkInId}.png`,
        data: checkInState.imageData!,
        options: {
          contentType: S3UploadType.PNG,
        },
      }).result;

      if (isOnboarding) {
        addOnboardingStep({ index: 1, name: "photo" });
      }
    } catch (error) {
      console.log("Error validating check-in photo! Error: ", error);
      Utils.toastErrorAutoClose(strings.toast.checkInPhotoError);
    }
  };

  /**
   * After validating and saving the check-in photo, create the check-in event
   */
  const createCheckInEvent = async () => {
    const input: CreateEventInput = {
      id: checkInState.checkInId,
      type: EventType.CheckIn,
      time: getCurrentTimeInSeconds(),
    };

    try {
      await createEventMutation({
        variables: {
          input: {
            id: input.id,
            type: input.type,
            time: input.time,
          },
        },
        onCompleted: () => {
          checkInState.checkInEventCreated = true;
          Utils.toastSuccess(strings.toast.checkInPhotoSuccess);
        },
      });
    } catch (error) {
      console.error("Error creating check-in event: ", error);
      Utils.toastErrorAutoClose(strings.toast.checkInPhotoError);
    }
  };

  /**
   * Add an error to the checkInState
   * @param error - The error message to add
   */
  const addError = (error: string) => {
    checkInState.errors.push(error);
  };

  /**
   * Mutation to validate the participant's check-in photo
   * - onCompleted callback will store the validation results in the checkInState
   */
  const performImageValidationMutation = async (
    input: ValidateParticipantImageInput,
  ) => {
    try {
      await validateImageMutation({
        variables: {
          input: {
            s3ImagePath: input.s3ImagePath,
          },
        },
        onCompleted: (data) => {
          const response: ValidateParticipantImageResponse =
            data.validateParticipantImage;
          if (!Utils.isNullOrEmpty(response)) {
            console.log("Validation results: ", response);
            checkInState.validationResults = response;
          }
        },
      });
    } catch (error) {
      console.error("An error occurred while validating the image: ", error);
      Utils.toastErrorAutoClose(strings.toast.generalError);
      retakePhoto();
    }
  };

  /**
   * Async function which performs the mutation to save raw events
   * @param type - The type of event to save
   */
  const saveRawEvent = async (type: EventType, data?: any) => {
    const input: SaveRawEventInput = {
      id: uuid(),
      type: type,
      clientTime: getCurrentTimeInSeconds(),
      data: JSON.stringify(data),
    };
    await performSaveRawEventMutation(input);
  };

  /**
   * Mutation to save raw events
   * - onCompleted callback will close the modal and display success toast message
   */
  const performSaveRawEventMutation = async (input: SaveRawEventInput) => {
    try {
      await saveRawEventMutation({
        variables: {
          input: {
            id: input.id,
            type: input.type,
            clientTime: input.clientTime,
            data: input.data,
          },
        },
      });
    } catch (error) {
      console.error("Error saving raw event: ", error);
    }
  };

  /**
   * Submit Answers button callback function
   * - Only clickable when all questions have been answered
   */
  const submitAnswers = async (questionAnswers: IdentifiableAnswerInput[]) => {
    setShowQuestions(false);
    setIsLoading(true);

    // Build the mutation input object
    const mutationInput: CreateParticipantAnswersInput = {
      promptId: pendingQuestions?.promptId ?? "",
      questionGroupId: questionGroup?.id ?? "",
      createdAt: getCurrentTimeInSeconds(),
      checkInId: checkInState.checkInId,
      identifiableAnswers: questionAnswers,
    };

    // Call the function to perform the mutation
    await performCreateParticipantAnswersMutation(mutationInput);
  };

  /**
   * Mutation to save the participant's answers to the questions
   * - onCompleted callback will close the modal and display success toast message
   */
  const performCreateParticipantAnswersMutation = async (
    input: CreateParticipantAnswersInput,
  ) => {
    try {
      await createParticipantAnswersMutation({
        variables: {
          input: {
            promptId: input.promptId,
            questionGroupId: input.questionGroupId,
            createdAt: input.createdAt,
            identifiableAnswers: input.identifiableAnswers,
          },
        },
        onCompleted: (data) => {
          // Close the modal on complete
          setTimeout(() => {
            closeModal();
            // Submitted Successfully
            if (data?.createParticipantAnswers) {
              Utils.toastSuccess(strings.toast.questionAnswersSuccess);

              // Refresh `Case` in the parent component
              refreshCase();

              saveRawEvent(EventType.AgencyQuestionAnswer, {
                checkInId: checkInState.checkInId,
                deviceInfo: deviceInfo,
              });
            } else {
              // We didn't catch an error from the API, so we'll display a generic error message
              Utils.toastError(strings.toast.questionAnswersError);
            }
          }, 1000);
        },
      });
    } catch (error) {
      console.error("Error submitting question answers: ", error);
      Utils.toastError(strings.toast.questionAnswersError);
      setTimeout(() => {
        closeModal();
      }, 1000);
    }
  };
  return (
    <Transition show={isOpen} as={Fragment}>
      {/* 
            * Setting `static` and a null close handler prevents the user from dismissing the dialog by
                clicking outside or pressing the `ESC` key.
            */}
      <Dialog static onClose={() => null}>
        {/*
         * The backdrop - rendered as a fixed sibling to the panel container
         * Rendered with fade-in/out transitions
         */}
        <Transition.Child
          as={Fragment}
          enter="ease-in duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/90" aria-hidden="true" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition-all ease-in-out duration-200"
          enterFrom="opacity-0 scale-50"
          enterTo="opacity-100 scale-100"
          leave="transition-all ease-in-out duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-50"
        >
          {/* Scrollable container */}
          <div className="fixed inset-0 w-screen overflow-y-auto  transition-all duration-300">
            {/* Container to center the panel */}
            <div className="flex min-h-full min-w-full items-center justify-center ">
              <Dialog.Panel className="min-w-[60%] max-w-[90%] min-h-[40vh] p-5 m-5 rounded-xl border-none shadow-md drop-shadow-md bg-white">
                {/* Loading/Submitting state */}
                {isLoading && renderSubmitting()}

                {/* First Step - CheckIn Photo */}
                {showWebCam && (
                  <>
                    <Dialog.Title>
                      <div className={styles.modalHeader}>
                        {strings.checkInModalTitle}
                      </div>
                    </Dialog.Title>
                    <Dialog.Description>
                      {strings.checkInPhotoNote}
                    </Dialog.Description>
                    <CheckInPhotoStep photoCaptured={photoCaptured} />
                  </>
                )}

                {/* Second Step - Questions */}
                {showQuestions && participantQuestions && (
                  <>
                    <Dialog.Title>
                      <div className={styles.modalHeader}>
                        {strings.questionsModalTitle}
                      </div>
                    </Dialog.Title>
                    <Dialog.Description>
                      {strings.questionsModalText}
                    </Dialog.Description>
                    <CheckInQuestionsForm
                      participantQuestions={participantQuestions}
                      submitAnswers={submitAnswers}
                    />
                  </>
                )}
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default CheckInProcess;
