/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";

export const GET_PARTICIPANT_CASE = gql`
  query GetParticipantCase {
    getParticipantCase {
      caseId
      onboardingCompletedAt
      participant {
        id
        firstName
        lastName
        __typename
      }
      currentOfficer {
        id
        firstName
        lastName
        active
        phone
        email
        __typename
      }
      nextQuestions {
        notAvailableReason
        time
        __typename
      }
      questionAssignment {
        questionGroupId
        agencyQuestionGroup {
          id
          localizedParticipantQuestions {
            id
            localization {
              text
              locale
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      pendingQuestions {
        startTime
        answersValidUntil
        promptId
        __typename
      }
      __typename
    }
  }
`;
