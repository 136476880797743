import type { PropsWithChildren } from "react";
import { useMemo } from "react";
import { awsConfig } from "../config";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { fetchAuthSession } from "aws-amplify/auth";
import { useAuthenticator } from "@aws-amplify/ui-react";

const httpLink = createHttpLink({
  uri: awsConfig.aws_appsync_graphqlEndpoint,
});

export const ApolloProviderWrapper = ({ children }: PropsWithChildren) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const client = useMemo(() => {
    const authMiddleware = setContext(async (operation, { headers }) => {
      if (user) {
        const tokens = (await fetchAuthSession())?.tokens;
        return {
          headers: {
            ...headers,
            authorization: `${tokens?.idToken}`,
          },
        };
      }
    });

    return new ApolloClient({
      link: from([authMiddleware, httpLink]),
      cache: new InMemoryCache(),
    });
  }, [user]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
